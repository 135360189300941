<template>
  <div class="visibility-regie-setting">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="content-regie">
        <v-col class="groupe" col xs="12" sm="6" md="5">
          <div class="title color-crm bold-700 font-text mt-2">
            {{ $i18n.locale === 'fr' ? 'Régies' : 'Régies' }}
          </div>
          <div
            class="list-users "
            v-if="getvisibiliteRegie && getvisibiliteRegie.length"
          >
            <v-list dense class="list-ensemble">
              <v-list-item-group color="#5C2DD3" class="list-ensemble-item">
                <v-list-item
                  button
                  @click.prevent.stop="handleClickRegie(regie)"
                  v-for="(regie, index) in getvisibiliteRegie"
                  :key="index"
                  :value="index.id"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="capitalize">
                        {{ regie.name }}</span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon
                    v-if="selectedRegie && selectedRegie.id == regie.id"
                  >
                    <font-awesome-icon class="color-crm" icon="check" />
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
          <div class="color-crm font-text font-sz-14 text-center mt-3" v-else>
            {{ $i18n.locale === 'fr' ? 'Aucun Régie' : 'No Régies' }}
          </div>
        </v-col>
        <v-col class="utilisateur border-left-solid" col xs="12" sm="6" md="7">
          <div class="title block-flex-align-space-between">
            <div class="color-crm bold-700 font-text mt-2">
              {{ $t('utilisateurs') }}
            </div>
            <div class="mr-2">
              <v-progress-circular
                v-if="getvisibiliteRegieLoading"
                indeterminate
                color="#5C2DD3"
                class="sz-loader-icon"
              ></v-progress-circular>
            </div>
          </div>
          <div class="bloc-select-multi">
            <v-autocomplete
              :clearable="true"
              color="#5C2DD3"
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Rechercher un utilisateur'
                  : 'Search user'
              "
              item-color="#5C2DD3"
              @input="handleSelect"
              return-object
              v-model="users"
              :loading="getvisibiliteRegieUsersLoading"
              :items="listUserVisibilityComputed || []"
              :persistent-placeholder="true"
              :label="$t('utilisateurs')"
              class="mb-0"
              :item-text="nameWithLang"
              dense
              item-value="id"
              :auto-focus="false"
              :no-data-text="
                $i18n.locale === 'fr'
                  ? 'Aucune utilisateurs trouvée'
                  : 'No users found'
              "
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
            >
            </v-autocomplete>
          </div>
          <div class="addListUsers" v-if="selectedRegie">
            <div
              class="list-users mt-2"
              :class="{
                'refact-height': selectedRegie && selectedRegie.length
              }"
              v-if="selectedRegie.users && selectedRegie.users.length"
            >
              <v-list class="list-item-user">
                <v-list-item
                  button
                  v-for="(user, index) in selectedRegie.users"
                  :key="user.id"
                  class="custom-list-group custom-list-group-vue"
                  :class="{
                    'border-top-solid': index == 0
                  }"
                >
                  <v-list-item-content>
                    <div class="block-flex-align-space-between">
                      <div>
                        <v-list-item-title
                          ><span class="font-sz-14 font-text bold-600">
                            {{ user.first_name }} {{ user.last_name }}</span
                          >
                          <strong class="color-crm font-sz-12 font-text">
                            ({{ user.type | typeFormat }})</strong
                          >
                        </v-list-item-title>
                        <div class="user">
                          <strong class="color-crm font-sz-11 font-text">
                            {{ user.email }}</strong
                          >
                        </div>
                      </div>
                      <v-btn
                        class="color-crm font-sz-14 bold-700 btn-deleted-user"
                        @click.prevent.stop="handleDeleteUserClick(user)"
                        :title="$t('deleted')"
                        fab
                        small
                        icon
                      >
                        X
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
            <div
              class="color-crm font-text font-sz-14 text-center mt-3"
              v-if="
                !getvisibiliteRegieLoading &&
                  selectedRegie &&
                  selectedRegie.users &&
                  selectedRegie.users.length == 0
              "
            >
              {{ $i18n.locale === 'fr' ? 'Aucun utilisateur' : 'No user' }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- Delete utilisteur -->
    <v-dialog v-model="ModalDeleteUsers" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer un utilisateur' : 'Delete user'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDeleteUsers')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? "l'utilisateur" : 'the user'
              })
            }}
            <strong> {{ userToDelete ? userToDelete.full_name : '' }}</strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getvisibiliteRegieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getvisibiliteRegieError" class="error-msg">
              <ul v-if="Array.isArray(getvisibiliteRegieError)">
                <li v-for="(e, index) in getvisibiliteRegieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getvisibiliteRegieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerUser"
            :loading="getvisibiliteRegieLoading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDeleteUsers')" small>
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Visiblity',
  data() {
    return {
      selectedRegie: null,
      selectedUser_id: null,
      users: null,
      userToDelete: null,
      initLoading: true,
      ModalDeleteUsers: false
    }
  },
  methods: {
    ...mapActions([
      'fetchListeUsersVoirEquipe',
      'fetchListeTeamsRegie',
      'fetchListeUsersEquipe',
      'makeTeamVisibleUsers',
      'makeTeamInvisibleUsers',
      'resetErrorVisibilityRegie'
    ]),
    closeDialog(ref) {
      this[ref] = false
      this.resetErrorVisibilityRegie()
    },
    nameWithLang({ full_name, type }) {
      let formatType = ''
      switch (type) {
        case 'user.admin':
          formatType = 'Utilisateur Admin'
          break
        case 'user.agent':
          formatType = 'Agent'
          break
        case 'user.resp.plan':
          formatType = 'Responsable Planning'
          break
        case 'user.supervisor':
          formatType = 'Régie Superviseur'
          break
        case 'user.commercial':
          formatType = 'Régie Commercial'
          break
        case 'user.final':
          formatType = 'Régie Utilisateur Final'
          break
        case 'user.technicien':
          formatType = 'Filiale Poseur'
          break
        case 'user.administration':
          formatType = 'GE Administration'
          break
        case 'user.client':
          formatType = 'Client'
          break
        case 'user.commercial.ite':
          formatType = 'Filiale Commercial'
          break
        default:
          formatType = ''
          break
      }
      return `${full_name} (${formatType})`
    },
    async handleClickRegie(regie) {
      this.selectedRegie = regie
      this.fetchListeUsersVoirEquipe({
        regie,
        id: regie.id,
        exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
      })
      await this.fetchListeUsersEquipe({
        regie,
        id: regie.id,
        exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
      })
    },
    handleDeleteUserClick(user) {
      this.userToDelete = user
      this.ModalDeleteUsers = true
    },
    async handleSelect(user) {
      const response = await this.makeTeamVisibleUsers({
        regie: this.selectedRegie,
        usersToAdd: user,
        exclude: [
          'pipeline.cstages',
          'stage.csubstages',
          'visibleToUsers.visibilityFilter',
          'team',
          'visibleTeams',
          'userPipe'
        ]
      })
      if (response) {
        this.users = null
        this.resetErrorVisibilityRegie()
      }
    },
    async supprimerUser() {
      const response = await this.makeTeamInvisibleUsers({
        regie: this.selectedRegie,
        usersToDelete: this.userToDelete,
        exclude: [
          'pipeline.cstages',
          'stage.csubstages',
          'visibleToUsers.visibilityFilter',
          'team',
          'visibleTeams',
          'userPipe'
        ]
      })
      if (response) {
        this.resetErrorVisibilityRegie()
        this.closeDialog('ModalDeleteUsers')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getvisibiliteRegie',
      'getVisibilityListeUsers',
      'getvisibiliteRegieError',
      'getvisibiliteRegieLoading',
      'getvisibiliteRegieUsersLoading'
    ]),

    listUserVisibilityComputed: function() {
      return this.getVisibilityListeUsers.filter(user => {
        if (this.selectedRegie && this.selectedRegie.users != null) {
          return (
            this.selectedRegie.users.findIndex(item => item.id === user.id) < 0
          )
        }
      })
    }
  },
  async mounted() {
    await this.fetchListeTeamsRegie()
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'GE Administration'
        case 'user.client':
          return 'Client'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visibility-regie-setting {
  .groupe {
    .margin {
      margin: 12px;
    }
    .title {
      padding: 10px 0px;
    }
    .list-ensemble {
      padding: 0px 0px;

      .list-ensemble-item {
        .custom-list-group {
          border-top: 0px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;

          border-left: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
        }
      }
    }
    .list-users {
      max-height: calc(100vh - 297px) !important;
      height: calc(100vh - 297px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
      cursor: pointer;
      border-top: 1px solid #d8d8d8;
    }
    .list-users::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .list-users::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .list-users::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }
  .utilisateur {
    .title {
      padding: 10px 0px;
    }
    .bloc-select-multi {
      .postion-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: -19px;
        .button-add-users {
          font-size: 11px;
        }
      }
    }
    .addListUsers {
      .chargement {
        text-align: center;
        color: #2dabe2;
      }
      .list-item-user {
        padding: 0px 0 !important;

        .v-list-item__content {
          padding: 10px 0px !important;
        }
        .custom-list-group {
          border-top: 0px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;
          border-left: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
        }
      }
      .list-users {
        max-height: calc(100vh - 373px) !important;
        height: calc(100vh - 373px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
        &.refact-height {
          max-height: calc(100vh - 440px) !important;
          height: calc(100vh - 440px) !important;
          margin-bottom: 0;
          overflow-y: auto;
          overflow-x: auto;
        }
      }
      .list-users::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .list-users::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 4px;
        background-color: rgba(238, 238, 238, 1) !important;
      }
      .list-users::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(189, 189, 189, 1);
      }
    }
  }
}
</style>
